<template>
  <nut-navbar
    @on-click-back="returnClick()"
    title="SView用户使用协议"
  ></nut-navbar>
  <div class="content">
    <div class="detail-box">
      <h4 class="title">一、特别提示</h4>
      <p>
        在此特别提醒您（用户）在注册成为SView用户之前，请认真阅读本《SView用户服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协
        议。您同意并点击确认本协议条款且完成注册程序后，才能成为SView的正式注册用户，并享受SView的各类服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止SView用户注册程序，并可以选择不使用本网站服务。
        本协议约定SView与用户之间关于“三维图纸预览”服务（以下简称“服务”
        ）的权利义务。“用户”是指注册、登录、使用本服务的个人、单位。本协议可由SView随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中查阅最新版协议条款。
        在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用SView提供的服务，用户继续使用SView提供的服务将被视为接受修改后的协议。
      </p>
      <h4 class="title">二、账号注册</h4>
      <p>
        1、用户在使用本服务前需要注册一个“SView”账号。“SView”账号应当使用手机号码绑定注册，请用户使用尚未与“SView”账号绑定的手机号码，以及未被SView根据本协议封禁的手机号码注册“SView”账号。SView可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
      </p>
      <p>
        2、如果注册申请者有被SView封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，SView将拒绝其注册申请。
      </p>
      <h4 class="title">三、账户安全</h4>
      <p>
        1、用户一旦注册成功,成为SView的用户,将得到一个用户名和密码,并有权利使用自己的用户名及密码随时进行登录SView。
      </p>
      <p>
        2、用户对用户名和密码的安全负全部责任,同时对以其用户名进行的所有活动和事件负全责。
      </p>
      <p>
        3、用户对密码加以妥善保管,切勿将密码告知他人,因密码保管不善而造成的所有损失由用户自行承担。
      </p>
      <p>
        4、如果用户泄漏了密码,有可能导致不利的法律后果,因此不管任何原因导致用户的密码安全受到威胁,应该立即和SView客服人员取得联系,否则后果自负。
      </p>
      <h4 class="title">四、用户声明与保证</h4>
      <p>
        1、用户承诺其为具有完全民事行为能力的民事主体,且具有达成交易履行其义务的能力。
      </p>
      <p>
        2、用户通过使用SView的过程中所制作、上载、复制、发布、传播的任何內容,包括但不限于账号头像、名称、用户说明等注册信息及认证资料,或文字、语音、图片、视频、图文等发送、回复和相关链接页面,以及其他使用账号或本服务所产生的内容,不得违反国家相关法律制度,包含但不限于如下原则:
      </p>
      <p>(1)违反宪法所确定的基本原则的；</p>
      <p>(2)危害国家安全,泄露国家秘密,颠覆国家政权,破坏国家统一的；</p>
      <p>(3)损害国家荣誉和利益的；</p>
      <p>(4)煽动民族仇恨、民族歧视,破坏民族团结的；</p>
      <p>(5)破坏国家宗教政策,宣扬邪教和封建迷信的；</p>
      <p>(6)散布谣言,扰乱社公秩序,破坏社会稳定的；</p>
      <p>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
      <p>(8)侮辱或者诽谤他人,侵害他人合法权益的；</p>
      <p>(9)含有法律、行政法规禁止的其他内容的。</p>
      <p>
        4、用户不得利用“SView”账号或本服务制作、上载、复制、发布、传播下干扰“SView”的正常运营,以及侵犯其他用户或第三方合法权益的内容：
      </p>
      <p>(1)含有任何性或性暗示的；</p>
      <p>(2)含有辱骂、恐吓、威胁内容的；</p>
      <p>(3)含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
      <p>(4)涉及他人隐私、个人信息或资料的；</p>
      <p>(5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
      <p>
        (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
      </p>
      <h4 class="title">五、服务内容</h4>
      <p>
        1、SView是由山东山大华天有限公司提供的三维模型手机端预览服务,用户通过SView打开近三十种格式的二维及三维CAD图纸，并支持多种格式的办公文档阅读。
      </p>
      <p>
        2、SView有权随时审核或删除用户发布/
        传播的涉嫌违法或违反社会主义精神文明,或者被SView认为不妥当的内容(包括但不限于图纸文件、文字、语音、图片、视频图文等)。
      </p>
      <h4 class="title">六、服务的终止</h4>
      <p>1、在下列情况下, SView有权终止向用户提供服务；</p>
      <p>
        （1）在用户违反本服务协议相关规定时，SView有权终止向该用户提供服务；如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，SView有权直接单方面终止向该用户提供
        服务；
      </p>
      <p>
        （2）如SView通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法接通，SView以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联
        系方式，SView有权终止向该用户提供服务；
      </p>
      <p>
        （3）一旦SView发现用户提供的数据或信息中含有虚假内容，
        SView有权随时终止向该用户提供服务；
      </p>
      <p>（4）本服务条款终止或更新时，用户明示不愿接受新的服务条款；</p>
      <p>（5）以及其他SView认为需要终止服务的情况。</p>
      <p>
        2、服务终止后，SView没有义务为用户保留原账号中或与之相关的任何图纸、文档，或转发任何未阅读或发送的信息给用户或第三方。
      </p>
      <p>
        3、用户理解并同意，即便在本协议终止及用户的服务被终止后， SView仍有权：
      </p>
      <p>（1）继续续保存您的用户信息；</p>
      <h4 class="title">七、服务的变更、中断</h4>
      <p>
        1、鉴于网络服务的特殊性，用户需同意SView会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。
      </p>
      <p>
        2、SView需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，
        SView无需为此承担任何责任。
      </p>
      <h4 class="title">八、服务条款修改</h4>
      <p>
        1、SView有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，SView将会通过适当方式向用户提示修改内容。
      </p>
      <p>
        2、如果不同意SView对本服务条款所做的修改，用户有权停止使用网络服务。
      </p>
      <p>
        3、如果用户继续使用网络服务，则视为用户接受SView对本服务条款所做的修改。
      </p>
      <h4 class="title">九、免责与赔偿声明</h4>
      <p>
        1、若SView已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
      </p>
      <p>
        2、用户明确同意其使用SView所存在的风险将完全由其自己承担，因其使用SView而产生的一切后果也由其自己承担。
      </p>
      <p>
        3、用户同意保障和维护SView及其他用户的利益，由于用户在使用SView有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给SView及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。
      </p>
      <h4 class="title">十、隐私声明</h4>
      <p>1、适用范围：</p>
      <p>（1）在用户注册SView账户时，根据要求提供的个人注册信息；</p>
      <p>
        （2）在用户使用SView，或访问其相关网页时，SView自动接收并记录的用户浏览器上的服务器数值，包括但不限于IP
        地址等数据及用户要求取用的网页记录。
      </p>
      <p>2、信息使用：</p>
      <p>
        （1）SView不会向任何人出售或出借用户的个人信息，用户个人空间中的文件，除非事先得到用户的许可；
      </p>
      <p>
        （2）SView亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。任何用户如从事上述活动，一经发现，SView有权立即终止与该用户的服务协议，查封其账号；
      </p>
      <p>3、信息披露：用户的个人信息将在下述情況下部分或全部被披露：</p>
      <p>（1）经用用户同意，向第三方披露；</p>
      <p>
        （2）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      </p>
      <p>
        （3）如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；
      </p>
      <p>
        （4）为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息；
      </p>
      <p>（5）其它SView根据法律或者网站政策认为合适的披露；</p>
      <h4 class="title">十一、其他</h4>
      <p>
        1、SView郑重提醒用户注意本协议中免除责任和和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
      </p>
      <p>
        2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和SView之间发生任何纠纷或争议，首先应友好协解决，协商不成的，用户同意将纠纷或争议提交SView住所地有管辖权的人民法院管辖。
      </p>
      <p>
        3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
      </p>
      <p>
        4、本协议最终解释权归山东山大华天软件有限公司所有，并且保留一切解释和修改的权力。
      </p>
      <p>5、本协议自2020年1月1日起适用。</p>
    </div>
    <div class="blank10"></div>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'useragreement',
  setup() {
    const router = useRouter()
    //返回登录页面
    const returnClick = () => {
      router.push('/phoneWeb/register')
    }

    return {
      returnClick,
    }
  },
}
</script>
<style scoped>
</style>
